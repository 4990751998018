<script setup>
defineProps({
  title: { type: String, required: true, default: ""},
  subtitle: { type: String, required: true, default: ""},
  location: { type: String, required: true, default: ""},
  logo: { type: String, required: true, default: ""},
  background: { type: String, required: true, default: ""},
  openingdays: { type: String, required: true, default: ""},
  urlGoogleMaps: { type: String, required: true, default: ""},
  urlInstagram: { type: String, required: true, default: ""},
  urlWaze: { type: String, required: true, default: ""},
  urlMenu: { type: String, required: true, default: ""},
  is_loaded: { type: Boolean, default: false},
})


const size_with = window.innerWidth / 8  // To compress

import imgResto from "../assets/resto.png"
import imgCafe from "../assets/cafe.png"
import imgBoutique from "../assets/boutique.png"


const links = [
  { id: 1, image: imgResto, title: 'Restaurant', href: "#restaurant", property: "lg:-skew-y-2"},
  { id: 2, image: imgCafe, title: 'Salon de Thé', href: "#salondethe", property: "lg:-mt-3"},
  { id: 3, image: imgBoutique, title: 'Boutique', href: "#boutique", property: "lg:skew-y-2"},
]
</script>

<template>
  <header class="inset-x-0 top-0 z-50">
    <div class="relative isolate bg-cover bg-center bg-zinc-400"
      :style="{ backgroundImage: is_loaded ? 'url(' + background + '?fit=crop&w=' + size_with + ')' : '' }">
      <div class="backdrop-blur px-6 pt-14 pb-6 lg:pb-40">
        <div class="mx-auto max-w-2xl mb-12 py-6 sm:py-6 lg:py-6 bg-white rounded-lg bg-opacity-90">
          <div class="flex items-center justify-center py-4 ">
            <div v-if="is_loaded"><img class="h-32 w-auto rounded-full bg-gray-400" :src=logo alt="Logo" /></div>
            <div v-else="is_loaded" class="animate-pulse flex h-32 w-32 bg-gray-400 rounded-full" />
          </div>
          <div class="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
            <div class="text-center">
              <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl" id="dancing-script-maintitle">
                <div v-if="is_loaded">{{ title }}</div>
                <div v-else="is_loaded" class="animate-pulse h-14 bg-gray-300 rounded border-b border-t"/>
              </h1>
              <p class="mt-2 text-lg leading-8 text-gray-600">
                <span v-if="is_loaded">{{ subtitle }}</span>
                <div v-else="is_loaded" class="animate-pulse mx-40 h-6 bg-gray-300 rounded border-b border-t"/>
              </p>
              <p class="text-lg font-bold leading-8 text-gray-600">
                <span v-if="is_loaded">{{ location }}</span>
                <div v-else="is_loaded" class="animate-pulse mx-48 h-6 bg-gray-300 rounded border-b border-t"/>
              </p>
              <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                <!-- <div
                class="flex items-center text-center p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
                role="alert">
                <div class="ml-2 w-full">
                  <div class="ml-2">
                    <b>Dernière information :</b> Ouverture le 32 Juin !
                  </div>
                </div>
              </div> -->
                <p class="text-3xl font-bold leading-9 tracking-tight text-black">
                  <span v-if="is_loaded">{{ openingdays }}</span>
                  <div v-else="is_loaded" class="animate-pulse mx-48 h-6 bg-gray-300 rounded border-b border-t"/>
                </p>
                <!-- <div class="flex items-center justify-center ">
                  <div class="columns-2 w-64">
                    <div class="flex flex-col-reverse">
                      <dt class="text-base leading-7 text-gray-700">12h30 - 14h</dt>
                      <dd class="text-xl font-bold leading-9 tracking-tight text-black">Le midi</dd>
                    </div>
                    <div class="flex flex-col-reverse">
                      <dt class="text-base leading-7 text-gray-700">19h00 - 22h</dt>
                      <dd class="text-xl font-bold leading-9 tracking-tight text-black">Le soir</dd>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="mt-10 flex items-center justify-center gap-x-6">
                <a :href=urlGoogleMaps class="text-sm font-semibold leading-6 text-gray-900">Y aller avec Google Maps
                  <span aria-hidden="true">→</span></a>
                <a :href=urlWaze class="text-sm font-semibold leading-6 text-gray-900">Y aller avec Waze <span
                    aria-hidden="true">→</span></a>
              </div>
              <div class="columns-1 mt-6">
                <!-- <a :href=urlMenu>
                  <div
                    class="w-full text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Voir le menu du restaurant
                  </div>
                </a> -->
                <a :href=urlInstagram>
                  <div
                    class="w-full text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                    Retrouvez nous sur Instagram
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="gap-6 mx-6 grid lg:grid-cols-3 lg:mx-40 sm:grid-cols-1 lg:space-y-0 -mt-40"> -->
    <div class="grid -mt-6 md:grid-cols-3 md:gap-20 grid-cols-1 lg:space-y-0 lg:-mt-40 md:mx-10 2xl:mx-60">
      <div v-for="link in links" :key="link.id" class="group relative">
        <a :href=link.href>
          <div class="grid relative hover:scale-110 transition-all duration-500 cursor-pointer bg-cover bg-center h-40 md:h-72"
          :class=link.property
            :style="{ backgroundImage: 'url('+ link.image +')' }">
            <p class="text-center mt-2 font-semibold text-xl text-gray-900 place-self-center lg:mt-64">
              <span
                class="before:block before:absolute before:-inset-3 before:-skew-y-3 before:bg-[#24533c] relative inline-block group-hover:before:-inset-8">
                <span class="relative text-white">{{link.title}}</span>
              </span>
            </p>
          </div>
        </a>
      </div>
    </div>
  </header>
</template>